@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}

body {
  font-size: 10px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
  overflow-x: hidden !important;
}
